import axios from 'src/app-axios';
import Constants from 'src/constants';

export const dashboard = {
  namespaced: true,
  state: {
    dataUsageStatistics: {},
    lastUpdatedAt: null,
    projectIssuesCounts: [],
    currentActiveCheckIns: [],
    currentSuspiciousActivities: [],
    longestProjectDelays: [],
    activeCheckInsCount: [],
    leastActiveAppUsers: [],
    dashboardCounts: {},
  },
  getters: {
    getDataUsageStatistics (state) {
      return state.dataUsageStatistics;
    },
    getLastUpdatedAt (state) {
      return state.lastUpdatedAt;
    },
    getProjectIssuesCounts (state) {
      return state.projectIssuesCounts;
    },
    getCurrentActiveCheckIns (state) {
      return state.currentActiveCheckIns;
    },
    getCurrentSuspiciousActivities (state) {
      return state.currentSuspiciousActivities;
    },
    getLongestProjectDelays (state) {
      return state.longestProjectDelays;
    },
    getActiveCheckInsCount (state) {
      return state.activeCheckInsCount;
    },
    getLeastActiveAppUsers (state) {
      return state.leastActiveAppUsers;
    },
    getDashboardCounts (state) {
      return state.dashboardCounts;
    },
  },
  mutations: {
    setDataUsageStatistics (state, data) {
      state.dataUsageStatistics = data;
    },
    setLastUpdatedAt (state, dateTime) {
      state.lastUpdatedAt = dateTime;
    },
    setProjectIssuesCounts (state, details) {
      state.projectIssuesCounts = details;
    },
    setCurrentActiveCheckIns (state, details) {
      state.currentActiveCheckIns = details;
    },
    setCurrentSuspiciousActivities (state, details) {
      state.currentSuspiciousActivities = details;
    },
    setLongestProjectDelays (state, details) {
      state.longestProjectDelays = details;
    },
    setActiveCheckInsCount (state, count) {
      state.activeCheckInsCount = count;
    },
    setLeastActiveAppUsers (state, details) {
      state.leastActiveAppUsers = details;
    },
    setDashboardCounts (state, data) {
      state.dashboardCounts = data;
    },
  },
  actions: {
    async fetchProjectIssuesCounts ({ dispatch, commit }, payload) {
      const url = 'api/projects/active-project-issues';
      try {
        const response = await axios.get(url, payload);
        commit('setProjectIssuesCounts', response.data);
      } catch (exception) {
        dispatch('alert/onAlert', {
          message: 'Something went wrong in getting the project issues counts.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
      }
    },
    async fetchCurrentActiveCheckInsData ({ dispatch, commit }) {
      const url = 'api/organizations/project-active-users';
      try {
        const response = await axios.get(url);
        commit('setCurrentActiveCheckIns', response.data);
      } catch (exception) {
        dispatch('alert/onAlert', {
          message: 'Something went wrong in getting your current active checkins data.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
      }
    },
    async fetchCurrentSuspiciousActivities ({ dispatch, commit }, payload) {
      const url = 'api/suspicious-activity';
      try {
        const response = await axios.get(url, payload);
        commit('setCurrentSuspiciousActivities', response.data);
      } catch (exception) {
        dispatch('alert/onAlert', {
          message: 'Something went wrong in getting your current suspicious activities count.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
      }
    },
    async fetchLongestProjectDelays ({ dispatch, commit }, payload) {
      const url = 'api/organizations/projects-delayed';
      try {
        const response = await axios.get(url, payload);
        commit('setLongestProjectDelays', response.data);
      } catch (exception) {
        dispatch('alert/onAlert', {
          message: 'Something went wrong in getting the projects with longest completion delays.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
      }
    },
    async fetchActiveCheckInsCount ({ dispatch, commit }) {
      const url = 'api/organizations/active-users';
      try {
        const response = await axios.get(url);
        commit('setActiveCheckInsCount', response.data);
      } catch (exception) {
        dispatch('alert/onAlert', {
          message: 'Something went wrong in getting your active check-ins count.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
      }
    },
    async fetchDataUsageStatistics ({ dispatch, commit }, { params }) {
      const url = 'api/organizations/data-usage';
      try {
        const response = await axios.get(url, { params: params });
        commit('setDataUsageStatistics', response.data);
      } catch (exception) {
        dispatch('alert/onAlert', {
          message: 'Something went wrong in getting your data usage statistics.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
      }
    },
    lastUpdatedAt ({ commit, state }, payload) {
      if (state.lastUpdatedAt !== null) {
        return;
      }
      commit('setLastUpdatedAt', payload.updatedAt);
    },
    async fetchLeastActiveAppUsers ({ dispatch, commit }) {
      const url = 'api/organizations/least-active-users';
      try {
        const details = await axios.get(url);
        commit('setLeastActiveAppUsers', details.data);
      } catch (exception) {
        dispatch('alert/onAlert', {
          message: 'Something went wrong in getting least active app users list.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
      }
    },
    async fetchDashboardCounts ({ dispatch, commit }) {
      const url = 'api/dashboard/counts';
      try {
        const details = await axios.get(url);
        commit('setDashboardCounts', details.data);
      } catch (exception) {
        dispatch('alert/onAlert', {
          message: 'Something went wrong in getting the Dashboard counts',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
      }
    },
  },
};
