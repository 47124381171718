import { render, staticRenderFns } from "./JobsList.vue?vue&type=template&id=12f19e14&scoped=true"
import script from "./JobsList.vue?vue&type=script&lang=js"
export * from "./JobsList.vue?vue&type=script&lang=js"
import style0 from "./JobsList.vue?vue&type=style&index=0&id=12f19e14&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12f19e14",
  null
  
)

export default component.exports