import appAxios from 'src/app-axios';
import Constants from 'src/constants';

export const projects = {
    namespaced: true,
    state: {
        projectsList: [],
        projectBudgets: [],
        projectTypes: [],
        projectDetails: {},
        budgetCheckInRecords: {},
        budgetChangeOrders: [],
        projectCompanies: [],
        budgetDetails: {},
        projectCompanyAppUsers: [],
        appUserProjectCheckIns: [],
        projectIssues: [],
        projectIssueCheckIns: {},
        projectContracts: [],
        projectMainContracts: [],
        projectMainContractDetails: {},
        projectContractDetails: {},
        contractItemCheckIns: {},
        contractCheckIns: {},
        performanceTrackingItems: [],
        trackingItemInstances: [],
        trackingItemInstanceDetails: {},
        instanceTimeSheets: {},
        projectSecondNavDrawerTab: null,
        projectMembers: [],
        ProjectScheduleLinks: [],
        ProjectSchedulePublicLinks: [],
        projectsCount: {},
    },
    getters: {
        getProjectsList (state) {
            return state.projectsList;
        },
        getProjectTypes (state) {
            return state.projectTypes;
        },
        getProjectBudgets (state) {
            return state.projectBudgets;
        },
        getProjectDetails (state) {
            return state.projectDetails;
        },
        getBudgetCheckInRecords (state) {
            return state.budgetCheckInRecords;
        },
        getBudgetChangeOrders (state) {
            return state.budgetChangeOrders;
        },
        getProjectCompanies (state) {
            return state.projectCompanies;
        },
        getProjectCompanyAppUsers (state) {
            return state.projectCompanyAppUsers;
        },
        getBudgetDetails (state) {
            return state.budgetDetails;
        },
        getAppUserProjectCheckIns (state) {
            return state.appUserProjectCheckIns;
        },
        getProjectIssues (state) {
            return state.projectIssues;
        },
        getProjectIssueCheckIns (state) {
            return state.projectIssueCheckIns;
        },
        getProjectContracts (state) {
            return state.projectContracts;
        },
        getProjectMainContracts (state) {
            return state.projectMainContracts;
        },
        getProjectMainContractDetails (state) {
            return state.projectMainContractDetails;
        },
        getProjectContractDetails (state) {
            return state.projectContractDetails;
        },
        getContractItemCheckIns (state) {
            return state.contractItemCheckIns;
        },
        getContractCheckIns (state) {
            return state.contractCheckIns;
        },
        getPerformanceTrackingItems (state) {
            return state.performanceTrackingItems;
        },
        getTrackingItemInstances (state) {
            return state.trackingItemInstances;
        },
        getTrackingItemInstanceDetails (state) {
            return state.trackingItemInstanceDetails;
        },
        getInstanceTimeSheets (state) {
            return state.instanceTimeSheets;
        },
        getProjectSecondNavDrawerTab (state) {
            return state.projectSecondNavDrawerTab;
        },
        getProjectMembers (state) {
            return state.projectMembers;
        },
        getProjectScheduleLinks (state) {
            return state.ProjectScheduleLinks;
        },
        getProjectSchedulePublicLinks (state) {
            return state.ProjectSchedulePublicLinks;
        },
        getProjectsCounts (state) {
            return state.projectsCount;
        },
    },
    mutations: {
        setProjectsList (state, data) {
            state.projectsList = data;
        },
        setProjectTypes (state, data) {
            state.projectTypes = data;
        },
        setProjectBudgets (state, budgets) {
            state.projectBudgets = budgets;
        },
        setProjectDetails (state, data) {
            state.projectDetails = data;
        },
        setBudgetCheckInRecords (state, checkIns) {
            state.budgetCheckInRecords = checkIns;
        },
        setBudgetChangeOrders (state, changeOrders) {
            state.budgetChangeOrders = changeOrders;
        },
        setProjectCompanies (state, companies) {
            state.projectCompanies = companies;
        },
        setProjectCompanyAppUsers (state, appUsers) {
            state.projectCompanyAppUsers = appUsers;
        },
        setBudgetDetails (state, details) {
            state.budgetDetails = details;
        },
        setAppUserProjectCheckIns (state, checkIns) {
            state.appUserProjectCheckIns = checkIns;
        },
        setProjectIssues (state, issues) {
            state.projectIssues = issues;
        },
        setProjectIssueCheckIns (state, issue) {
            state.projectIssueCheckIns = issue;
        },
        setProjectContracts (state, contracts) {
            state.projectContracts = contracts;
        },
        setProjectMainContracts (state, contracts) {
            state.projectMainContracts = contracts;
        },
        setProjectMainContractDetails (state, details) {
            state.projectMainContractDetails = details;
        },
        setProjectContractDetails (state, details) {
            state.projectContractDetails = details;
        },
        setContractItemCheckIns (state, checkIns) {
            state.contractItemCheckIns = checkIns;
        },
        setContractCheckIns (state, checkIns) {
            state.contractCheckIns = checkIns;
        },
        setPerformanceTrackingItems (state, items) {
            state.performanceTrackingItems = items;
        },
        setTrackingItemInstances (state, instances) {
            state.trackingItemInstances = instances;
        },
        setTrackingItemInstanceDetails (state, details) {
            state.trackingItemInstanceDetails = details;
        },
        setInstanceTimeSheets (state, timeSheets) {
            state.instanceTimeSheets = timeSheets;
        },
        setProjectSecondNavDrawerTab (state, tab) {
            state.projectSecondNavDrawerTab = tab;
        },
        removeStoredProjectDrawerTab (state) {
            state.projectSecondNavDrawerTab = null;
        },
        setProjectMembers (state, data) {
            state.projectMembers = data;
        },
        setProjectBudgetLinks (state, link) {
            if (state.ProjectScheduleLinks.some(item => item.id === link.id)) {
                return;
            }
            state.ProjectScheduleLinks.push(link);
        },
        removeStoredBudgetLink (state, id) {
            const filteredBudgetLinks = state.ProjectScheduleLinks.filter(item => item.id !== id);
            state.ProjectScheduleLinks = filteredBudgetLinks;
        },
        clearAllStoredBudgetLinks (state) {
            state.ProjectScheduleLinks = [];
        },
        setStoredScheduleLinks (state, links) {
            if (Object.keys(links).length > 0) {
                state.ProjectScheduleLinks = links.schedule;
                return;
            }
            state.ProjectScheduleLinks = [];
        },
        setStoredPublicScheduleLinks (state, links) {
            if (Object.keys(links).length > 0) {
                state.ProjectSchedulePublicLinks = links.schedule;
                return;
            }
            state.ProjectSchedulePublicLinks = [];
        },
        setStoredPublicScheduleLinksVersion (state, links) {
            if (Object.keys(links).length > 0) {
                state.ProjectSchedulePublicLinks = links;
                return;
            }
            state.ProjectSchedulePublicLinks = [];
        },
        setProjectCounts (state, data) {
            state.projectsCount = data;
        },
    },
    actions: {
        async fetchProjectsList ({ dispatch, commit }) {
            const url = 'api/projects';
            try {
                const projects = await appAxios.get(url);
                commit('setProjectsList', projects.data.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the projects list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchPaginatedProjectsList ({ dispatch, commit }, payload) {
            const url = 'api/projects';
            try {
                const projects = await appAxios.get(url, payload);
                commit('setProjectsList', projects.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the projects list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async deleteProject ({ dispatch }, payload) {
            const url = `api/projects/${payload.projectId}`;
            try {
                await appAxios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in deleting this project.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async createProject ({ dispatch }, payload) {
            const url = 'api/projects';
            try {
            const response = await appAxios.post(url, payload);
              return response;
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating this project.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchProjectTypes ({ dispatch, commit }) {
            const url = 'api/projects/types';
            try {
                const types = await appAxios.get(url);
                commit('setProjectTypes', types.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the projects types list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchProjectBudgets ({ dispatch, commit }, payload) {
            const url = `api/projects/${payload.projectId}/budgets`;
            try {
                const projects = await appAxios.get(url);
                commit('setProjectBudgets', projects.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the project budgets list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchProjectDetails ({ dispatch, commit }, payload) {
            const url = `api/projects/${payload.projectId}`;
            try {
                const response = await appAxios.get(url);
                commit('setProjectDetails', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the project details',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async updateProjectDetails ({ dispatch }, payload) {
            const url = `api/projects/${payload.projectId}`;
            try {
                await appAxios.put(url, payload.updateDetails);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating the project details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async createProjectBudget ({ dispatch }, payload) {
            const url = `api/projects/${payload.projectId}/budgets`;
            try {
              await appAxios.post(url, payload.details);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating this budget.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchBudgetCheckInRecords ({ dispatch, commit }, payload) {
            const url = `api/budgets/${payload.budgetId}/checkins`;
            try {
                const checkins = await appAxios.get(url, payload.params);
                commit('setBudgetCheckInRecords', checkins.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the budget check-in records.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchBudgetChangeOrders ({ dispatch, commit }, payload) {
            const url = `api/budgets/${payload.budgetId}/change-orders`;
            try {
                const changeOrders = await appAxios.get(url);
                commit('setBudgetChangeOrders', changeOrders.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the budget change orders.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async createBudgetChangeOrder ({ dispatch }, payload) {
            const url = `api/budgets/${payload.budgetId}/change-orders`;
            try {
              await appAxios.post(url, payload.details);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating this project.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async deleteChangeOrder ({ dispatch }, payload) {
            const url = `api/budgets/${payload.budgetId}/change-orders/${payload.changeOrderId}`;
            try {
                await appAxios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in deleting this project.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchProjectCompanies ({ dispatch, commit }, payload) {
            const url = `api/projects/${payload.projectId}/project-companies`;
            try {
                const companies = await appAxios.get(url);
                commit('setProjectCompanies', companies.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the project companies list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async deleteProjectCompany ({ dispatch }, payload) {
            const url = `api/project-companies/${payload.projectCompanyId}`;
            try {
                await appAxios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in deleting this project company.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async addProjectCompany ({ dispatch }, payload) {
            const url = `api/projects/${payload.projectId}/project-companies`;
            try {
              await appAxios.post(url, payload.details);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating this project.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchBudgetDetails ({ dispatch, commit }, payload) {
            const url = `api/budgets/${payload.budgetId}`;
            try {
                const details = await appAxios.get(url);
                commit('setBudgetDetails', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the budget details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async updateBudgetDetails ({ dispatch }, payload) {
            const url = `api/budgets/${payload.budgetId}`;
            try {
                await appAxios.put(url, payload.updateDetails);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating the budget details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchProjectCompanyAppUsers ({ dispatch, commit }, payload) {
            const url = `api/project-companies/${payload.projectCompanyId}/app-users`;
            try {
                const companies = await appAxios.get(url);
                commit('setProjectCompanyAppUsers', companies.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the project companies list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async removeProjectCompanyAppUser ({ dispatch }, payload) {
            const url = `api/projects/${payload.projectId}/project-companies/${payload.projectCompanyId}/app-users/${payload.appUserId}`;
            try {
                await appAxios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in deleting this project company app user.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async updateProjectAppUserStatus ({ dispatch }, payload) {
            const url = `api/project-companies/app-users/${payload.appUserId}`;
            try {
                await appAxios.put(url, payload.status);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating this project company app user.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async deleteProjectBudget ({ dispatch }, payload) {
            const url = `api/budgets/${payload.budgetId}`;
            try {
                await appAxios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in deleting this project budget.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchAppUserProjectCheckIns ({ dispatch, commit }, payload) {
            const url = `api/projects/${payload.projectId}/project-companies/${payload.projectCompanyId}/app-users/${payload.appUserId}/check-ins`;
            try {
                const checkins = await appAxios.get(url);
                commit('setAppUserProjectCheckIns', checkins.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the app user project check-in records.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchProjectIssues ({ dispatch, commit }, payload) {
            const url = `api/projects/${payload.projectId}/project-issues`;
            try {
                const issues = await appAxios.get(url);
                commit('setProjectIssues', issues.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the project issues list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async createProjectIssue ({ dispatch }, payload) {
            const url = `api/projects/${payload.projectId}/project-issues`;
            try {
                await appAxios.post(url, payload.details);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating this project issue.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async deleteProjectIssue ({ dispatch }, payload) {
            const url = `api/project-issues/${payload.projectIssueId}`;
            try {
                await appAxios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in deleting this project isssue.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async updateProjectIssueDetails ({ dispatch }, payload) {
            const url = `api/project-issues/${payload.projectIssueId}`;
            try {
                await appAxios.put(url, payload.details);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating this project issue details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchProjectIssueCheckIns ({ dispatch, commit }, payload) {
            const url = `api/project-issues/${payload.projectIssueId}/checkins`;
            try {
                const issue = await appAxios.get(url, payload.params);
                commit('setProjectIssueCheckIns', issue.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting this project issue checkins.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchProjectContracts ({ dispatch, commit }, payload) {
            const url = `api/projects/${payload.projectId}/contracts`;
            try {
                const contracts = await appAxios.get(url);
                commit('setProjectContracts', contracts.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the project contracts list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchContractsByMainContract ({ dispatch, commit }, payload) {
            const url = `api/projects/${payload.projectId}/contracts`;
            try {
                const contracts = await appAxios.get(url, payload.details);
                commit('setProjectContracts', contracts.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the filtered app users list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async createProjectContract ({ dispatch }, payload) {
            const url = `api/projects/${payload.projectId}/contracts`;
            try {
                await appAxios.post(url, payload.details);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating this contract.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchProjectContractDetails ({ dispatch, commit }, payload) {
            const url = `api/contracts/${payload.projectContractId}`;
            try {
                const details = await appAxios.get(url);
                commit('setProjectContractDetails', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting this contract details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async updateProjectContract ({ dispatch }, payload) {
            const url = `api/contracts/${payload.projectContractId}`;
            try {
                await appAxios.put(url, payload.updateDetails);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating this project contract.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async deleteProjectContract ({ dispatch }, payload) {
            const url = `api/contracts/${payload.projectContractId}`;
            try {
                await appAxios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in deleting this project contract.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async addContractItems ({ dispatch }, payload) {
            const url = `api/contracts/${payload.contractId}/items`;
            try {
                await appAxios.post(url, payload.details);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in adding these budget items to this contract.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async deleteContractItem ({ dispatch }, payload) {
            const url = `api/contracts/${payload.contractId}/items/${payload.contractItemId}`;
            try {
                await appAxios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in deleting this contract item.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchContractItemCheckIns ({ dispatch, commit }, payload) {
            const url = `api/budgets/${payload.contractItemId}/checkins`;
            try {
                const checkins = await appAxios.get(url, payload.params);
                commit('setContractItemCheckIns', checkins.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the contract item check-in records.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchContractCheckIns ({ dispatch, commit }, payload) {
            const url = `api/contracts/${payload.projectContractId}/checkins`;
            try {
                const response = await appAxios.get(url, payload.params);
                commit('setContractCheckIns', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the contract check-in records.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchProjectMainContracts ({ dispatch, commit }, payload) {
            const url = `api/projects/${payload.projectId}/main-contracts`;
            try {
                const contracts = await appAxios.get(url);
                commit('setProjectMainContracts', contracts.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the project main contracts list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async createProjectMainContract ({ dispatch }, payload) {
            const url = `api/projects/${payload.projectId}/main-contracts`;
            try {
                await appAxios.post(url, payload.details);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating this main contract.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async deleteProjectMainContract ({ dispatch }, payload) {
            const url = `api/main-contracts/${payload.mainContractId}`;
            try {
                await appAxios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in deleting this project main contract.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchProjectMainContractDetails ({ dispatch, commit }, payload) {
            const url = `api/main-contracts/${payload.mainContractId}`;
            try {
                const details = await appAxios.get(url);
                commit('setProjectMainContractDetails', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the project main contracts list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async updateProjectMainContract ({ dispatch }, payload) {
            const url = `api/main-contracts/${payload.mainContractId}/update`;
            try {
                await appAxios.put(url, payload.updateDetails);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating this project main contract.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchPerformanceTrackingItems ({ dispatch, commit }, payload) {
            const url = `api/projects/${payload.projectId}/performance-tracking-items`;
            try {
                const items = await appAxios.get(url);
                commit('setPerformanceTrackingItems', items.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the performance tracking items list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async addPerformanceTackingItems ({ dispatch }, payload) {
            const url = `api/projects/${payload.projectId}/performance-tracking-items`;
            try {
                await appAxios.post(url, payload.details);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in adding the performance tracking items.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchTrackingItemInstances ({ dispatch, commit }, payload) {
            const url = `api/performance-tracking-items/${payload.trackingItemId}/instances`;
            try {
                const items = await appAxios.get(url);
                commit('setTrackingItemInstances', items.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the tracking item instances list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async addTrackingItemInstance ({ dispatch }, payload) {
            const url = `api/performance-tracking-items/${payload.trackingItemId}/instances`;
            try {
                await appAxios.post(url, payload.details);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in adding the performance tracking items.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async deleteTrackingItem ({ dispatch }, payload) {
            const url = `api/performance-tracking-items/${payload.trackingItemId}`;
            try {
                await appAxios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in deleting this tracking item.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async deleteTrackingItemInstance ({ dispatch }, payload) {
            const url = `api/performance-tracking-instances/${payload.trackingItemInstanceId}`;
            try {
                await appAxios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in deleting this tracking item instance.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchTrackingItemInstanceDetails ({ dispatch, commit }, payload) {
            const url = `api/performance-tracking-instances/${payload.trackingItemInstanceId}`;
            try {
                const details = await appAxios.get(url);
                commit('setTrackingItemInstanceDetails', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the tracking item instance details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async updateTrackingItemInstance ({ dispatch }, payload) {
            const url = `api/performance-tracking-instances/${payload.trackingItemInstanceId}`;
            try {
                await appAxios.put(url, payload.updateDetails);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating this tracking item instance details',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchInstanceTimeSheets ({ dispatch, commit }, payload) {
            const url = `api/performance-tracking-instances/${payload.trackingItemInstanceId}/time-sheets`;
            try {
                const timeSheets = await appAxios.get(url, payload.params);
                commit('setInstanceTimeSheets', timeSheets.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the instance time sheets.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async updateTimeSheetDetails ({ dispatch }, payload) {
            const url = `api/time-sheets/${payload.timeSheetId}`;
            try {
                await appAxios.put(url, payload.updateDetails);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating this instance time sheet details',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async retryTimeSheetUpload ({ dispatch }, payload) {
            const url = `api/time-sheets/${payload.timeSheetId}/upload`;
            try {
                await appAxios.post(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong, please contact support.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchProjectsByHub ({ dispatch, commit }, payload) {
            const url = 'api/projects';
            try {
                const users = await appAxios.get(url, payload);
                commit('setProjectsList', users.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the filtered projects list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        storeProjectSecondNavDrawerTab ({ commit }, payload) {
            commit('setProjectSecondNavDrawerTab', payload.toTab);
        },
        clearStoredProjectDrawerTab ({ commit }) {
            commit('removeStoredProjectDrawerTab');
        },
        async fetchProjectMembers ({ dispatch, commit }, payload) {
            const url = `api/projects/${payload.projectId}/users`;
            try {
                const members = await appAxios.get(url);
                commit('setProjectMembers', members.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the project members list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async addProjectMember ({ dispatch }, payload) {
            const url = `api/projects/${payload.projectId}/users`;
            try {
                await appAxios.post(url, payload.details);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in adding the user to this project.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async deleteProjectMember ({ dispatch }, payload) {
            const url = `api/projects/${payload.projectId}/users/${payload.projectMemberId}`;
            try {
                await appAxios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: exception.response.data.message,
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        storeProjectBudgetLinks ({ commit }, payload) {
            commit('setProjectBudgetLinks', payload);
        },
        deleteStoredBudgetLink ({ commit }, payload) {
            commit('removeStoredBudgetLink', payload);
        },
        clearStoredBudgetLinks ({ commit }) {
            commit('clearAllStoredBudgetLinks');
        },
        async storeSavedProjectBudgetLinks ({ dispatch }, payload) {
            const url = `api/projects/${payload.projectId}/schedules`;
            try {
                await appAxios.post(url, payload.budgetLinks);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in saving the project schedules.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchBudgetsScheduleLinks ({ dispatch, commit }, payload) {
            const url = `api/projects/${payload.projectId}/schedules/draft`;
            try {
                const response = await appAxios.get(url);
                commit('setStoredScheduleLinks', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the project members list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchBudgetsPublicScheduleLinks ({ dispatch, commit }, payload) {
            const url = `api/projects/${payload.projectId}/schedules/published`;
            try {
                const response = await appAxios.get(url);
                commit('setStoredPublicScheduleLinks', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the project members list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async publishSchedule ({ dispatch }, payload) {
            const url = `api/projects/${payload.projectId}/publish`;
            try {
                await appAxios.post(url, payload.details);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in adding the user to this project.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        setVersionScheduleLinks ({ commit }, payload) {
            commit('setStoredPublicScheduleLinksVersion', payload.schedules);
        },
        async fetchProjectCounts ({ commit, dispatch }) {
            const url = 'api/organizations/project-counts';
            try {
                const response = await appAxios.get(url);
                commit('setProjectCounts', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the project counts.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
    },
};
