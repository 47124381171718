<template>
  <v-card class="pa-8 dashboard_cards">
    <div class="d-flex align-center">
      <img
        src="../../../assets/icons/myprojecticon.png"
        alt=""
        width="22px"
        class="mr-2"
      >
      <h4>My Projects</h4>
    </div>
    <div class="dashboard_card_count">
      <h1>{{ dashboardCounts.my_projects || 0 }}</h1>
      <p>Total Projects</p>
    </div>
  </v-card>
</template>
<script>
export default {
  name: 'DashboardProjectCount',
  computed: {
    dashboardCounts () {
        return this.$store.getters['dashboard/getDashboardCounts'];
      },
  },
};
</script>
<style lang="scss" scoped>
.dashboard_card_count{
    position: absolute;
    bottom: 10px;
}
.dashboard_cards{
    min-height: 200px;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    box-shadow: none !important;
    border: 1px solid #ccc;
    border-radius: 10px;
}
.dashboard_cards h4 {
    font-size: 22px;
    font-weight: 400;
    position: relative;
}
</style>
