<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <h2 class="title-style pt-3">
      Projects 📂
    </h2>
    <p class="mt-0 mb-0 project-subtitle">
      Overview of Active and Completed Projects.
    </p>
    <v-card
      shaped
      color="#fff"
      class="project-list-card"
    >
      <br>
      <v-col class="text-right">
        <v-btn
          v-if="partnerCheck()"
          text
          outlined
          class="mr-2"
          @click="syncProjectsFromPartner()"
        >
          <v-icon
            color="#ff3700"
            size="24"
            left
          >
            mdi-cloud-sync-outline
          </v-icon>
          <span class="sync-btn"> Sync Autodesk </span>
        </v-btn>
        <v-btn
          v-if="accessCheck('account_admin')"
          color="#000"
          class="mr-0"
          :disabled="(subscriptionCheck('trial'))"
          @click="showCreateDialog = true"
        >
          <v-icon class="mr-2">
            mdi-plus-circle-outline
          </v-icon> Create Project
        </v-btn>
      </v-col>
      <br>

      <v-row>
        <v-col cols="10">
          <v-card-title class="filter-card">
            <v-row>
              <v-col
                cols="12"
                lg="4"
                md="5"
                sm="12"
              >
                <v-text-field
                  v-model="search"
                  label="Search"
                  append-icon="mdi-magnify"
                  outlined
                  dense
                  :hint="isSearchAdded ? searchHint : ''"
                  @keyup.enter="handleSearch"
                  @keydown.esc="clearSearch"
                />
              </v-col>
              <v-spacer />
              <v-col
                v-if="partnerCheck()"
                cols="12"
                lg="4"
                md="2"
                sm="1"
                class="text-right"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      fab
                      dark
                      outlined
                      color="#37474F"
                      small
                      v-on="on"
                      @click="clearFilters()"
                    >
                      <v-icon
                        small
                        dark
                      >
                        mdi-file-multiple-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>All Projects</span>
                </v-tooltip>
              </v-col>
              <v-col
                v-if="partnerCheck()"
                cols="12"
                lg="4"
                md="5"
                sm="11"
              >
                <v-autocomplete
                  v-model="selectedHub"
                  :items="userSavedHubs"
                  item-text="name"
                  item-value="id"
                  label="Hubs*"
                  single-line
                  append-icon="mdi-hub"
                  outlined
                  dense
                  @input="filterProjectsByHub()"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <p
            v-if="partnerCheck()"
            class="info-text"
          >
            *Indicates from partner account
          </p>
          <v-data-table
            :headers="headers"
            :items="paginatedProjectList"
            :search="search"
            class="elevation-1 table-projects"
            disable-sort
            mobile-breakpoint="100"
            :items-per-page="itemsPerPage"
            :footer-props="{
              'items-per-page-options': [10, 20, 30]
            }"
            :server-items-length="totalFilteredRecords"
            :options.sync="options"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td
                  class="more-link"
                  @click="showProjectDetails(item.id)"
                >
                  <v-avatar
                    color="#000"
                    size="24"
                    class="mr-2"
                  >
                    <span class="white--text avatar-text">{{
                      item.name | filterProfile
                    }}</span>
                  </v-avatar>
                  {{ item.name
                  }}<span
                    v-if="item.database === false"
                    class="partner-project"
                  >*</span>
                </td>
                <td>
                  <span v-if="item.project_number !== null">
                    {{ item.project_number }}
                  </span>
                  <span v-else> N/A </span>
                </td>
                <td>
                  <v-progress-linear
                    color="green"
                    height="15"
                    :value="item.project_progress"
                    class="progress-bar"
                  >
                    <template v-slot:default="{ value }">
                      <strong>{{ Math.ceil(value) }}%</strong>
                    </template>
                  </v-progress-linear>
                </td>
                <td>{{ item.organization.name }}</td>
                <td>{{ item.type }}</td>
                <td>
                  <span v-if="item.start_date !== null">
                    {{ item.start_date | convertToLocal }}
                  </span>
                  <span v-else> N/A </span>
                </td>
                <td>
                  <span v-if="item.end_date !== null">
                    {{ item.end_date | convertToLocal }}
                  </span>
                  <span v-else> N/A </span>
                </td>
                <td>
                  <v-chip
                    :color="getColor(item.status)"
                    class="font-my-style"
                    outlined
                    small
                  >
                    {{ item.status | formatStatusVal }}
                  </v-chip>
                </td>
                <td>
                  <v-menu
                    bottom
                    origin="center center"
                    transition="slide-y-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        icon
                        v-bind="attrs"
                        class="action-btn"
                        color="#37474F"
                        v-on="on"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        @click.stop
                        @click="showProjectDetails(item.id)"
                      >
                        <v-icon class="mr-2">
                          mdi-eye-outline
                        </v-icon> View
                      </v-list-item>
                      <v-list-item
                        @click.stop
                        @click="editProject(item.id)"
                      >
                        <v-icon class="mr-2">
                          mdi-pencil-outline
                        </v-icon>Edit
                      </v-list-item>
                      <v-list-item
                        :disabled="item.database === false"
                        @click.stop
                        @click="deleteProject(item.id)"
                      >
                        <v-icon
                          class="mr-2"
                          :color="item.database === false ? '#00000042' : '#C62828'"
                        >
                          mdi-trash-can-outline
                        </v-icon>Delete
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="2">
          <v-row>
            <v-col cols="12">
              <v-card class="project-summary-cards one">
                <div>
                  <h4>Active Projects</h4>
                  <div class="d-flex justify-center">
                    <h1>{{ projectCounts.active || 0 }}</h1>
                    <v-icon
                      color="#A077FF"
                      large
                    >
                      mdi-chart-box-outline
                    </v-icon>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card class="project-summary-cards two">
                <div>
                  <h4>Pending Projects</h4>
                  <div class="d-flex justify-center">
                    <h1>{{ projectCounts.pending || 0 }}</h1>
                    <v-icon
                      color="#2BC6F1"
                      large
                    >
                      mdi-chart-box-outline
                    </v-icon>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card class="project-summary-cards three">
                <div>
                  <h4>Completed Projects</h4>
                  <div class="d-flex justify-center">
                    <h1>{{ projectCounts.completed || 0 }}</h1>
                    <v-icon
                      color="#C57D27"
                      large
                    >
                      mdi-chart-box-outline
                    </v-icon>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="py-3" />
    </v-card>
    <centre-spinner :loading="loading" />
    <delete-project
      v-if="showDelete"
      :title="'Delete this project ?'"
      :message="'Action will permanently remove the project and all its sub items, Do you want to continue ?'"
      @closed="showDelete = false"
      @confirmed="showConfirmDeleteForm()"
    />
    <confirm-project-delete
      v-if="showConfirmDelete"
      @closed="showConfirmDelete = false"
      @confirmed="deleteConfirmed()"
    />
    <partner-auth-check
      ref="partnerAuth"
      @partner-authorized="checkAccessLevel()"
    />
    <create-project
      v-if="showCreateDialog"
      class="create-project-sidebar"
      @closed="showCreateDialog = false"
      @success="getProjectsList"
    />
    <overlay-alert
      :show-progress="showProgress"
      :process-alert="processAlert"
    />
    <edit-project
      v-if="showEditDialog"
      :project-id="projectId"
      @closed="showEditDialog = false"
      @project-details-updated="getProjectsList"
    />
    <select-hubs
      v-if="showHubList"
      @closed="showHubList = false"
      @sync-projects="passSelectedProjects"
    />
    <sync-alert-dialog
      v-if="showSyncAlert"
      :title="'Import Projects'"
      :message="'Do you wish to add new projects to EzyBuild ?'"
      :cancel-button="'No'"
      :confirm-button="'Yes'"
      @closed="syncAndUpdateExistingProjects"
      @confirmed="displayHubList"
    />
    <sync-alert-dialog
      v-if="showSyncProjectAdmin"
      :title="'Update Projects'"
      :message="'Do you want to update your projects ?'"
      :cancel-button="'No'"
      :confirm-button="'Yes'"
      @closed="showSyncProjectAdmin = false"
      @confirmed="syncAndUpdateExistingProjects"
    />
  </v-container>
</template>
<script>
import moment from 'moment';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import Constants from 'src/constants';
import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
import ConfirmDeleteDialog from 'src/views/dashboard/component/ConfirmDeleteDialog';
import global from 'src/mixins/global';
import PartnerAuthCheck from 'src/views/dashboard/partnerauth/PartnerAuthCheck';
import CreateProject from './CreateProject.vue';
import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';
import EditProject from 'src/views/dashboard/projects/EditProject';
import SelectHubs from './SelectHubs.vue';

export default {
  name: 'ProjectsList',
  components: {
    'centre-spinner': spinner,
    'delete-project': DeleteDialog,
    'sync-alert-dialog': DeleteDialog,
    'confirm-project-delete': ConfirmDeleteDialog,
    'partner-auth-check': PartnerAuthCheck,
    'create-project': CreateProject,
    'overlay-alert': OverlayAlert,
    'edit-project': EditProject,
    'select-hubs': SelectHubs,
  },
  filters: {
    convertToLocal (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY');
    },
    formatStatusVal (val) {
      if (val === 'active') {
        return 'Active*';
      } else if (val === 'pending') {
        return 'Pending';
      } else if (val === 'completed') {
        return 'Completed*';
      } else {
        return val;
      }
    },
    filterProfile (str) {
      const firstName = str[0];
      const lastName = str[str.length - 1];
      return firstName + lastName;
    },
  },
  mixins: [global],
  data () {
    return {
      showForm: false,
      showDelete: false,
      deleteId: '',
      options: {},
      headers: [
        { text: 'Project Name', align: 'start', value: 'name' },
        { text: 'Number', value: 'project_number' },
        { text: 'Progress', value: 'project_progress' },
        { text: 'Organization', value: 'organization.name' },
        { text: 'Type', value: 'type' },
        { text: 'Start Date', value: 'start_date' },
        { text: 'End Date', value: 'location_timezone' },
        { text: 'Status', value: 'status' },
        { text: 'More', value: 'more' },
      ],
      loading: false,
      search: null,
      projectId: '',
      showConfirmDelete: false,
      showCreateDialog: false,
      showProgress: false,
      processAlert: '',
      showEditDialog: false,
      showHubList: false,
      newProjectsToSync: [],
      showSyncAlert: false,
      selectedHub: null,
      projectActive: null,
      projectPending: null,
      projectCompleted: null,
      showSyncProjectAdmin: false,
      page: 1,
      itemsPerPage: 10,
    };
  },
  computed: {
    projectsList () {
      return this.$store.getters['projects/getProjectsList'];
    },
    paginatedProjectList () {
      var list = this.$store.getters['projects/getProjectsList'];
      return list.data;
    },
    totalFilteredRecords () {
        if (Object.keys(this.projectsList).length > 0) {
          return this.projectsList.total;
        }
        return 0;
    },
    userHubs () {
      return this.$store.getters['partners/getUserHubs'];
    },
    userSavedHubs () {
      return this.$store.getters['partners/getUserSavedHubs'];
    },
    projectCounts () {
      return this.$store.getters['projects/getProjectsCounts'];
    },
    searchHint () {
      return `Press Enter to filter by name: '${this.search}' or press ESC to clear.`;
    },
    isSearchAdded () {
      if (this.search === null || this.search === '') {
        return false;
      }
      return true;
    },
  },
  watch: {
    options: {
      async handler () {
        await this.paginatedCall();
      },
      deep: true,
    },
  },
  async mounted () {
    this.loading = true;
    await this.fetchProjectCounts();
    await this.getProjectsList();
    this.loading = false;
  },
  methods: {
    async getProjectsList () {
      this.loading = true;
      await this.$store.dispatch('projects/fetchPaginatedProjectsList', {
        params: {
          limit: this.itemsPerPage,
          startPage: this.pageStart,
          hubId: this.selectedHub,
          name: this.search,
        },
      }).catch(() => {
        this.loading = false;
      });
      await this.getUserSavedHubs();
      this.loading = false;
    },
    async fetchProjectCounts () {
      this.loading = true;
      await this.$store.dispatch('projects/fetchProjectCounts').catch(() => {
        this.loading = false;
      });
    },
    async paginatedCall () {
      this.itemsPerPage = this.options.itemsPerPage;
      this.pageStart = this.options.page * this.itemsPerPage - this.itemsPerPage;
      this.getProjectsList();
    },
    async getUserSavedHubs () {
      this.loading = true;
      await this.$store.dispatch('partners/fetchUserSavedHubs').catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    deleteProject (id) {
      this.projectId = id;
      this.showDelete = true;
    },
    showConfirmDeleteForm () {
      this.showDelete = false;
      this.showConfirmDelete = true;
    },
    async deleteConfirmed () {
      this.loading = true;
      this.showConfirmDelete = false;
      await this.$store
        .dispatch('projects/deleteProject', {
          projectId: this.projectId,
        })
        .then((response) => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Project deleted successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.getProjectsList();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getColor (status) {
      if (status === 'completed') return '#FF3700';
      else if (status === 'active') {
        return '#2E7D32';
      } else if (status === 'pending') {
        return '#C62828';
      } else {
        return '#37474F';
      }
    },
    syncProjectsFromPartner () {
      this.$refs.partnerAuth.isPartnerAuthorized();
    },
    async syncAndUpdateExistingProjects () {
      this.showSyncAlert = false;
      this.showSyncProjectAdmin = false;
      await this.$store.dispatch('partners/fetchProjectsFromPartner', {
        newProjects: {
          projects: this.newProjectsToSync,
        },
      })
        .then((response) => {
          this.getProjectsList();
        });
    },
    async getProjectsFromPartner () {
      this.showSyncAlert = false;
      this.showProgress = true;
      this.processAlert = 'Syncing...';
      await this.$store
        .dispatch('partners/fetchProjectsFromPartner', {
          newProjects: {
            projects: this.newProjectsToSync,
          },
        })
        .then((response) => {
          this.showProgress = false;
          this.getProjectsList();
        })
        .catch(() => {
          this.showProgress = false;
        });
    },
    showProjectDetails (projectId) {
      this.$router.push(`/projects/${projectId}/details`);
    },
    editProject (projectId) {
      this.projectId = projectId;
      this.showEditDialog = true;
    },
    displayHubList () {
      this.showSyncAlert = false;
      this.showHubList = true;
    },
    passSelectedProjects (projects) {
      this.newProjectsToSync = projects;
      this.showHubList = false;
      this.showProgress = true;
      this.processAlert = 'Syncing...';
      const result = this.userHubs.filter((o1) =>
        this.newProjectsToSync.some((o2) => o1.hub_id === o2.hub_id),
      );
      const userSelectedHubs = [];
      for (const item of result) {
        userSelectedHubs.push({
          hub_id: item.hub_id,
          name: item.name,
        });
      }
      this.storeSelectedHubsInDb(userSelectedHubs);
    },
    async clearFilters () {
      this.options.page = 1;
      this.pageStart = 0;
      this.selectedHub = null;
      this.search = null;
      await this.getProjectsList();
    },
    async filterProjectsByHub () {
      this.options.page = 1;
      this.pageStart = 0;
      await this.getProjectsList();
    },
    async storeSelectedHubsInDb (userSelectedHubs) {
      await this.$store
        .dispatch('partners/storeUserSelectedHubs', {
          hubs: {
            hubs: userSelectedHubs,
          },
        })
        .then((response) => {
          this.getProjectsFromPartner();
        })
        .catch(() => {
          this.showProgress = false;
        });
    },
    checkAccessLevel () {
      if (this.accessCheck('account_admin')) {
        this.showSyncAlert = true;
      } else {
        this.showSyncProjectAdmin = true;
      }
    },
    async handleSearch () {
      this.options.page = 1;
      this.pageStart = 0;
      await this.getProjectsList();
    },
    async clearSearch () {
      this.options.page = 1;
      this.pageStart = 0;
      this.search = null;
      await this.getProjectsList();
    },
  },
};
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474f !important;
  font-weight: bold !important;
}

.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474f !important;
}

.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #cfd8dc;
}

.v-data-table {
  background-color: #eceff1;
}

.title-style {
  color: #37474f;
  font-size: 36px;
  font-weight: 600;
}

.icon-style {
  color: #37474f;
  font-size: 45px;
}

.font-my-style {
  text-transform: capitalize;
  font-weight: bold;
}

.sync-btn {
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
  text-transform: capitalize;
  margin-top: 3px;
  margin-left: 5px;
  font-size: 15px;
}

.partner-project {
  color: #ff3700;
  font-weight: bold;
}

.info-text {
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474f;
  margin-left: 15px;
  margin-top: -20px;
  margin-bottom: 0px !important;
}

.more-link {
  cursor: pointer;
}

.project-list-card {
  box-shadow: none !important;
  margin-top: 0px !important;
}

.project-summary-cards {
  padding: 30px;
  text-align: center;
  border-radius: 10px;
  box-shadow: none !important;
  border: 1px solid #ccc;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-summary-cards h1 {
  font-size: 32px;
}

.one {
  background: #e2d9f7;
  border: 1px solid #a077ff !important;
}

.two {
  background: #cfe5eb;
  border: 1px solid #2bc6f1 !important;
}

.three {
  background: #ffe0bb;
  border: 1px solid #c57d27 !important;
}

.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}

.table-projects {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}

.table-projects tbody tr:nth-of-type(even) {
  background: #fff !important;
}

.table-projects tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}

.action-btn {
  background: #fff !important;
}

.avatar-text {
  text-transform: uppercase;
  font-weight: bold;
}

.project-subtitle {
  margin-bottom: 0px !important;
  color: #aaa;
}

.progress-bar {
  border-radius: 50px !important;
}
</style>
