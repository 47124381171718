<template>
  <v-card class="pa-8 dashboard_cards">
    <div class="d-flex align-center">
      <img
        src="../../../assets/icons/activecheckinsicon.png"
        width="30px"
        alt=""
        class="mr-2"
      >
      <h4>Active Check-Ins</h4>
    </div>
    <div class="dashboard_card_count">
      <h1>{{ dashboardCounts.active_check_ins || 0 }}</h1>
      <p>{{ lastUpdatedAt | diffHumans }}</p>
    </div>
  </v-card>
</template>
<script>
import moment from 'moment';
export default {
    name: 'DashboardActiveCheckins',
    filters: {
      diffHumans (val) {
        return moment(val).fromNow();
      },
      changeFormat (val) {
        return Number(val);
      },
    },
    computed: {
      lastUpdatedAt () {
        return this.$store.getters['dashboard/getLastUpdatedAt'];
      },
      dashboardCounts () {
        return this.$store.getters['dashboard/getDashboardCounts'];
      },
    },
    mounted () {
        this.lastUpdated();
    },
    methods: {
      lastUpdated () {
        this.$store.dispatch('dashboard/lastUpdatedAt', {
          updatedAt: moment().toISOString(),
        });
      },
    },
};
</script>
<style scoped>
.dashboard_card_count{
    position: absolute;
    bottom: 10px;
}
.dashboard_cards h4 {
    font-size: 22px;
    font-weight: 400;
    position: relative;
}
</style>
